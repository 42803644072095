import { VStack, useBreakpointValue } from '@chakra-ui/react'
import { LegacyHeader } from 'atg/components/legacy-header-r2'
import { GlobalSearch } from 'composable/components/global-search/global-search'
import {
  algoliaIndexNameResolver,
  algoliaQuerySuggestionsIndexNameResolver,
} from 'composable/components/global-search-shared/utils'
import { MegaMenuDetailsBar } from 'composable/components/mega-menu/MegaMenuDetailsBar'
import { FrontasticImage } from 'frontastic/lib/image'
import { useAtgLegacyCart } from '../../../contexts'

type TasticProps = {
  data: {
    logo?: FrontasticImage & { title: { en_US: string } }
    homeUrl?: string
  }
}

const LegacyHeaderTasticR2: React.FC<TasticProps> = ({ data }) => {
  const { cartTotalItems } = useAtgLegacyCart()
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })
  const { logo, homeUrl } = data

  return (
    <VStack>
      <LegacyHeader
        brandLogo={logo}
        homeUrl={homeUrl || '/'}
        searchComponent={
          <GlobalSearch
            algoliaIndexNameResolver={algoliaIndexNameResolver}
            algoliaQuerySuggestionsIndexNameResolver={algoliaQuerySuggestionsIndexNameResolver}
          />
        }
        verticalButtonProps={{
          textDecoration: 'none',
          size: 'md',
          minW: 'auto',
        }}
        cartQuantity={cartTotalItems}
      />
      {isMobile && <MegaMenuDetailsBar />}
    </VStack>
  )
}

export default LegacyHeaderTasticR2
